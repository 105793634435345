import React from "react";
import styled from "styled-components";
import { Box, BoxProps } from "../box";

const LoadingWheelBox = styled(Box)`
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top-style: solid;
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  border-top-color: rgba(255, 255, 255, 0.2);
  border-right-color: rgba(255, 255, 255, 0.2);
  border-left-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: ${({ theme }) => theme.colors.mofoGreen};
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  overflow: hidden;

  &,
  &::after {
    border-radius: 50%;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export interface LoadingWheelProps extends BoxProps {
  variant?: "sm" | "md" | "lg";
  borderWidth?: string;
}

export const LoadingWheel = ({
  variant = "md",
  borderWidth = "1.1em",
  ...rest
}: LoadingWheelProps): JSX.Element => {
  const size = {
    sm: "5em",
    md: "10em",
    lg: "15em",
  }[variant];
  return <LoadingWheelBox size={size} borderWidth={borderWidth} {...rest} />;
};
